import { useState } from "react"

import {API_BASE_URL} from "../utilities/Constants"


export function useConfirmPassword() {
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)

  const confirmPassword = async (token, password) => {
    setIsLoading(true);
    setError(null)

    // console.log(JSON.stringify({ password }));
    try {

      const response = await fetch(API_BASE_URL+`/forgot_password/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password: password })
      });


      const json = await response.json();
      if (!response.ok) {
        setIsLoading(false)
        setError(json.message)
      } else {
        // console.log(json)
        setIsLoading(false)
      }

    } catch (e) {
      console.log(e)
    }
  };
  return { confirmPassword, isLoading, error };
}

