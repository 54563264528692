import { useModalContext } from "../../context/ModalContext";
import TextField from "../../components/forms_comp/TextField";
import CustomRow from "../../components/CustomRow";
import { useState, useEffect } from "react";
import {API_URL} from "../../utilities/Constants"

function PaymentInfo() {
  const statusAvailable = [
    "Initiated",
    "Awaiting payment",
    "Issued",
    "Paid",
    "Cancelled",
    "Refunded",
  ];
  const [selectedStatus, setSelectedStatus] = useState("");
  const [montant, setMontant] = useState(100);
  const [type, setType] = useState("type");
  const [mode, setMode] = useState("orange");
  const [startDate, setStartDate] = useState("2021-01-01");
  const [avatar, setAvatar] = useState("");

  const productId = 7; //need to be changed
  const userId = 160; //need to be changed

  useEffect(() => {
    const fetchAvatar = async () => {
      const response = await fetch(
        API_URL+`/users/${userId}`
      );
      const data = await response.json();
      setAvatar(data["avatarFile"]);
    };
    fetchAvatar();
  }, []);

  async function handleUpdate() {
    // console.log(JSON.stringify({ status: selectedStatus }));
    await fetch(
      API_URL+`/payments/${productId}/status`,

      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ status: selectedStatus }),
      }
    );
    setSelectedStatus("");
  }

  const { setIsModalShow } = useModalContext();
  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Paimenet Info
        </h2>

        <CustomRow
          description="sam smith"
          containerStyle="space-x-2 flex flex-col mb-3"
          textStyle={`capitalize text-[13px] leading-5 font-semibold`}
        >
          {!avatar && (
            <img
              src="/assets/avatar.png"
              alt="avatar"
              className="w-20 h-20 object-cover object-center rounded-full mb-3"
            />
          )}
        </CustomRow>

        <div>
          <TextField
            type="text"
            label="montant:"
            value={montant}
            setValue={setMontant}
            readonly={true}
          />
          <TextField
            type="text"
            label="status:"
            readonly={true}
            isOptions={true}
            value={selectedStatus}
            setValue={setSelectedStatus}
            optionsValues={statusAvailable}
          />
          <TextField
            type="text"
            label="type:"
            value={type}
            setValue={setType}
            readonly={true}
          />

          <TextField
            type="text"
            label="mode:"
            value={mode}
            setValue={setMode}
            readonly={true}
          />
          <TextField
            type="date"
            label="paymentDate:"
            value={new Date(startDate).toISOString().split("T")[0]}
            setValue={setStartDate}
            readonly={true}
          />
        </div>

        <div className="flex space-x-4 mt-6 text-sm justify-center">
          <button
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
            onClick={handleUpdate}
          >
            update
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;
