import { apiSlice } from "../slices/apiSlice";

export const temoignagesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query({
      query: () => "/temoignages",
      providesTags: ["Review"],
    }),

    creatReview: builder.mutation({
      query: (data) => ({
        url: "/temoignages",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Review"],
    }),

    deleteReview: builder.mutation({
      query(id) {
        return {
          url: `/temoignages/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Review"],
    }),
  }),
});

export const { useGetReviewsQuery, useCreatReviewMutation,useDeleteReviewMutation } = temoignagesApi;
