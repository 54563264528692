import { createContext, useContext, useReducer, useState } from "react";
import AdminForm from "../components/admin_comp/AdminForm";
import CityForm from "../components/cities_comp/CityForm";
import CityImport from "../components/cities_comp/CityImport";
import ComandForm from "../components/commands_comp/ComandForm";
import CouponForm from "../components/coupons_comp/CouponForm";
import CouponInfo from "../components/coupons_comp/CouponInfo";
import CancelOffer from "../components/offers_comp/CancelOffer";
import OfferForm from "../components/offers_comp/OfferForm";
import OptionForm from "../components/option_comp/OptionForm";
import OptionInfo from "../components/option_comp/OptionInfo"
import OptionEdit from "../components/option_comp/OptionEdit"
import PaperInfo from "../components/users_comp/PaperInfo";

import OptionDelete from "../components/option_comp/OptionDelete";
import PayementMethodEdit from "../components/payments_comp/PayementMethodEdit"
import PayementMethodInfo from "../components/payments_comp/PayementMethodInfo"
import PayementMethodDelete from "../components/payments_comp/PayementMethodDelete"
import PayementMethodForm from "../components/payments_comp/PayementMethodeForm"


import UserForm from "../components/users_comp/UserForm";
import PayementInfo from "../components/payments_comp/PayementForm";
import CityFormEdit from "../components/cities_comp/CityFormEdit";

import NewsletterForm from "../components/newsletter_comp/NewsletterForm"
import NewsletterInfo from "../components/newsletter_comp/NewsletterInfo"
import TemoignageForm from "../components/temoignage_comp/TemoignageForm";
import TemoignageInfo from "../components/temoignage_comp/TemoignageInfo"

const ModalContext = createContext();

function componentReducer(state, action) {
  switch (action.type) {
    case "COMAND_FORM":
      return { component: <ComandForm /> };
    case "CITY_FORM":
      return { component: <CityForm /> };
    case "CITY_IMPORT":
      return { component: <CityImport /> };
    case "CITY_FORM_EDIT":
      return { component: <CityFormEdit CityInfo={action.payload} /> }
    case "OFFER_FORM":
      return { component: <OfferForm /> };
    case "CANCEL_OFFER":
      return { component: <CancelOffer commandInfo={action.payload} /> };
    case "COUPON_FORM":
      return { component: <CouponForm /> };
    case "COUPON_INFO":
      return { component: <CouponInfo couponInfo={action.payload} /> };
    case "ADMIN_FORM":
      return { component: <AdminForm /> };
    case "USER_PAPERINFO":
      return { component: <PaperInfo fileSrc={action.payload} /> };
    case "OPTION_FORM":
      return { component: <OptionForm /> };
    case "OPTION_INFO":
      return { component: <OptionInfo optionInfo={action.payload} /> }
    case "OPTION_EDIT":
      return { component: <OptionEdit optionInfo={action.payload} /> }
    case "OPTION_DELETE":
      return { component: <OptionDelete optionInfo={action.payload} /> }

    case "PAYEMENT_METHOD_FORM":
      return { component: <PayementMethodForm /> }
    case "PAYEMENT_METHOD_INFO":
      return { component: <PayementMethodInfo optionInfo={action.payload} /> }
    case "PAYEMENT_METHOD_EDIT":
      return { component: <PayementMethodEdit optionInfo={action.payload} /> }
    case "PAYEMENT_METHOD_DELETE":
      return { component: <PayementMethodDelete optionInfo={action.payload} /> }

    case "USER_FORM":
      return { component: <UserForm /> }
    case "PAYMENT_INFO":
      return { component: <PayementInfo /> }
    case "ADD_TO_NEWSLETTER":
      return { component: <NewsletterForm /> }
    case "NEWSLETTER_INFO":
      return { component: <NewsletterInfo optionInfo={action.payload} /> };
    case "ADD_TO_TEMOIGNAGE":
      return { component: <TemoignageForm /> }
    case "TEMOIGNAGE_INFO":
      return { component: <TemoignageInfo optionInfo={action.payload} /> }

    default:
      return state;
  }
}

export function useModalContext() {
  const context = useContext(ModalContext);

  if (!context) {
    throw Error("useAuthContext must be used inside an AuthContextProvider");
  }

  return context;
}

export function ModalContextProvider({ children }) {
  const [isModalShow, setIsModalShow] = useState(false);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [state, dispatch] = useReducer(componentReducer, {
    component: null,
  });

  return (
    <ModalContext.Provider
      value={{
        ...state,
        fetchAgain,
        setFetchAgain,
        dispatch,
        setIsModalShow,
        isModalShow,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
