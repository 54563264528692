import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./context/UserContext";
import { ModalContextProvider } from "./context/ModalContext";
import { AuthContextProvider } from "./context/AuthContext";
import { Provider } from "react-redux";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthContextProvider>
        <ModalContextProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </ModalContextProvider>
      </AuthContextProvider>
    </Provider>
  </BrowserRouter>
);
