import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../context/ModalContext";
import TextField from "../forms_comp/TextField";
import { useCreatReviewMutation } from "../../redux/apiCalls/temoignage";
import { toast } from "react-toastify";

function TemoignageForm() {
  const { setIsModalShow } = useModalContext();

  const [fullname, setFullname] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState("");
  const [createReview] = useCreatReviewMutation();

  const [error, setError] = useState(false);

  const navigate = useNavigate();

  async function handleForm(_e) {
    if (!status || !fullname || !rate || !description) {
      setError("Tous les champs sont obligatiores");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    try {
      await createReview({
        fullname,
        status,
        description,
        rate: Number(rate),
      }).unwrap();
      toast.success("Review created successful");
      navigate("/temoignage");
      setIsModalShow(false);
    } catch (error) {
      console.log(error);
    }

    // let httpRes = await fetch(API_URL+"/temoignages", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     fullname,
    //     status,
    //     description,
    //     rate: Number(rate),
    //   }),
    // });

    // let data = await httpRes.json();

    // if (httpRes.ok) {
    //   navigate("/temoignage");
    //   setIsModalShow(false);
    // } else {
    //   console.log(data);
    // }
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Temoignage
        </h2>

        <TextField
          type="text"
          label="Full Name"
          required
          value={fullname}
          setValue={setFullname}
        />
        <TextField
          type="text"
          label="description"
          required
          value={description}
          setValue={setDescription}
        />
        <TextField
          type="text"
          label="rate"
          required
          value={rate}
          setValue={setRate}
        />
        <TextField
          type="text"
          label="status"
          required
          value={status}
          setValue={setStatus}
        />
        {error && <p className="text-center text-sm text-red-400">{error}</p>}
        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={handleForm}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            crée
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}
export default TemoignageForm;
