import {API_BASE_URL} from "../utilities/Constants"

export function useForgotPassword() {

  const forgotPassword = async (email) => {
    // console.log(JSON.stringify({ email }));
    await fetch(API_BASE_URL+"/forgot_password/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });



  };
  return { forgotPassword };
}
