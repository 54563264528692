import CustomRow from "../CustomRow";

const LastPayments = ({ data }) => {
  return (
    <div className="grid grid-cols-2 place-items-start px-4 py-3 gap-x-6">
      <CustomRow
        description="sam smith"
        containerStyle="w-full relative space-x-2 "
        underTitle={data.payment.mode}
        textStyle="text-sm capitalize text-logoBlack leading-4 font-medium"
        underStyle="capitalize text-fontColor text-[10px] font-normal"
      >
        <img
          src="/assets/avatar.png"
          alt="avatar"
          className="w-9 h-9 object-cover object-center rounded-full"
        />

        <p className="absolute -bottom-3 left-9 text-[10px] text-fontColor">
          {new Date(data.payment.createdAt).toLocaleString("fr", {
            timeStyle: "medium",
          })}
          &nbsp;&nbsp;
          {new Date(data.payment.createdAt).toLocaleString("fr", {
            dateStyle: "short",
          })}
        </p>
      </CustomRow>

      <p
        className={`font-semibold justify-self-end ${
          data.payment.mode === "virement" ? "text-logoGreen" : "text-red-500"
        }`}
      >
        {data.payment.montant.toLocaleString("fr-ca", {
          style: "currency",
          currency: "FGN",
        })}
      </p>
    </div>
  );
};

export default LastPayments;
