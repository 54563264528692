import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { ActiveEye, NoActiveEye } from "../icons/index"
import { useVerifyToken } from "../hooks/useVerifyToken"
import { useConfirmPassword } from "../hooks/useConfirmPassword"

export default function ConfirmNewPassword() {
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const navigate = useNavigate();
  const { verifyToken, error: tokenError, isLoading: tokenIsLoading } = useVerifyToken()
  const { confirmPassword } = useConfirmPassword()


  const location = useLocation();
  const token = (location.pathname.split("/")[2])

  const onSubmit = async (e) => {
    e.preventDefault();
    await verifyToken(token);
    if (!tokenError && !tokenIsLoading)
      await confirmPassword(token, password)
    navigate("/")
  }

  return (
    <div className="relative h-full min-h-screen px-8 py-20 bg-background bg-cover bg-center flex flex-col justify-start content-center items-center">
      <div className="mb-36 ">
        <img
          className="w-72 mx-auto mb-0 block "
          src="../assets/Nabhorelan-logo.png"
          alt="logo"
        />
      </div>

      <div className=" w-full sm:w-[600px] md:w-[700px] lg:w-[800px] rounded-3xl px-10 py-5  md:px-32 md:py-10 bg-white/20 shadow-black ">
        <div className="mb-14">
          <h2 className="font-bold text-4xl text-logoGreen mb-1.5 text-center">Mot de passe oublié</h2>
          <p className="text-lg text-black text-center">Écrivez votre nouveau mot de passe</p>
        </div>
        <form onSubmit={onSubmit}>
          <div className={"relative flex flex-wrap w-full mb-3"}>
            <input
              className={"outline-none border focus:border-logoGreen rounded-lg w-full px-[15px] py-4 "}
              placeholder={"Mot de passe"}
              type={!showPassword ? "password" : "text"}
              value={password}
              onChange={(e) => { console.log(e.target.value); setPassword(e.target.value) }}
            />
            <span className="z-10 mr-2 flex justify-around h-full  text-center text-logoGreen absolute bg-transparent rounded text-base items-center right-2 ">
              {showPassword ? <ActiveEye width="25px" height="25px" className="cursor-pointer" listener={() => setShowPassword(!showPassword)} /> :
                <NoActiveEye width="25px" height="25px" className="cursor-pointer" listener={() => setShowPassword(!showPassword)} />}
            </span>
          </div>
          <div className={"relative flex flex-wrap w-full"}>
            <input
              className={" outline-none border focus:border-logoGreen rounded-lg w-full px-[15px] py-4 "}
              placeholder={"Confirmez le mot de passe"}
              type={!showConfirmedPassword ? "password" : "text"}
              value={confirmedPassword}
              onChange={(e) => { setConfirmedPassword(e.target.value) }}
            />
            <span className="z-10 mr-2 flex justify-around h-full  text-center text-logoGreen absolute bg-transparent rounded text-base items-center right-2 ">
              {showConfirmedPassword ? <ActiveEye width="25px" height="25px" className="cursor-pointer" listener={() => setShowConfirmedPassword(!showPassword)} /> :
                <NoActiveEye width="25px" height="25px" className="cursor-pointer" listener={() => setShowConfirmedPassword(!showPassword)} />}
            </span>
          </div>
          <button className="mt-8 mb-4 text-white rounded-lg w-full h-[50px] transition-colors duration-300 ease-out bg-logoGreen hover:bg-logoGreen/80">
            suivant
          </button>
        </form>
      </div>
    </div>


  )
}

