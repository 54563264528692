import { useState } from "react"
import { useForgotPassword } from "../hooks/useForgotPassword"

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [nextStep, setNextStep] = useState(false)
  const { forgotPassword } = useForgotPassword()

  const onSubmit = async (e) => {
    e.preventDefault();
    await forgotPassword(email)
    if (!nextStep)
      setNextStep(true)
  }

  return (
    <div className="relative h-full min-h-screen px-8 py-20 bg-background bg-cover bg-center flex flex-col justify-start content-center items-center">
      <div className="mb-36 ">
        <img
          className="w-72 mx-auto mb-0 block "
          src="./assets/Nabhorelan-logo.png"
          alt="logo"
        />
      </div>

      <div className=" w-full sm:w-[600px] md:w-[700px] lg:w-[800px] rounded-3xl px-14 py-5  md:px-32 md:py-10 bg-white/20 shadow-black ">
        <div className="mb-14">
          <h2 className="font-bold text-4xl text-logoGreen mb-1.5 text-center">Mot de passe oublié</h2>
          <p className="text-lg text-black text-center">Restaurer votre mot de passe</p>
        </div>
        <form onSubmit={onSubmit}>
          {
            !nextStep
              ?
              <>
                <input
                  className={`${nextStep ? "invisible" : ""} outline-none border focus:border-logoGreen rounded-lg w-full px-[15px] py-4 my-9 bg-white/70`}
                  placeholder={"Email"}
                  type="email"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                />
              </>
              :
              <div className="my-[64px]">
                <p className="w-full text-center text-xl">Nous vous envoyons un code <br />sur votre numéro de téléphone</p>
              </div>
          }
          {/* {console.log({ nextStep, isLoading, error })} */}
          {!nextStep && <button className="mt-7 mb-4 text-white rounded-lg w-full h-[50px] transition-colors duration-300 ease-out bg-logoGreen hover:bg-logoGreen/80">
            suivant
          </button>}
        </form>
      </div>
    </div>


  )
}

