import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneField({ label, required, value, setValue, readonly }) {
  return (
    <div className="w-full grid grid-cols-4 items-center h-fit mb-3 text-sm text-logoBlack">
      {label && <label htmlFor={label}>{label}</label>}

      <PhoneInput
        inputProps={{
          required,
          readOnly: readonly,
        }}
        specialLabel={false}
        country="ma"
        value={value}
        onChange={(phone) => setValue(phone)}
        containerClass="-ml-3 rounded-md col-span-3"
        containerStyle={{
          width: "calc(100% + 12px)",
        }}
        inputStyle={{
          outline: "none",
          border: "1px solid #F1F1F1",
          backgroundColor: "#F1F1F1",
          width: "100%",
          padding: "0 16px 0 50px",
        }}
        buttonStyle={{
          border: "none",
        }}
        countryCodeEditable={false}
      />
    </div>
  );
}

export default PhoneField;
