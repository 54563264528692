function CustomRow({
  children,
  description,
  containerStyle,
  textStyle,
  listener,
  underTitle,
  underStyle,
}) {
  return (
    <div onClick={listener} className={`flex items-center shrink-0 ${containerStyle}`}>
      {children}
      <p className={textStyle}>
        {description}
        <br />
        {underTitle && <span className={underStyle}>{underTitle}</span>}
      </p>
    </div>
  );
}

export default CustomRow;
