import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../context/ModalContext";
import { checkEmailIsValidate } from "../../utilities";
import CheckedBox from "../CheckedBox";
import PhoneField from "../forms_comp/PhoneField";
import TextField from "../forms_comp/TextField";
import { useCreatUserMutation } from "../../redux/apiCalls/usersApi";
import { userRequest } from "../../requestMethods";
import { toast } from "react-toastify";

function UserForm() {
  const { setIsModalShow } = useModalContext();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Masculin");
  const [role, setRole] = useState("ALL");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [CreateUser, { isLoading, error: ErrorToCreate }] =
    useCreatUserMutation();

  async function handleForm(e) {
    if (!fname || !lname || !phone || !email || !role) {
      setError("Tous les champs sont obligatiores");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    if (!checkEmailIsValidate(email).isValid) {
      setError(checkEmailIsValidate(email).errorMsg);
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    try {
      const newuser = await CreateUser({
        email,
        phone,
        profil: {
          firstname: fname,
          lastname: lname,
          birthdate: "2023-12-12",
          gender,
          bio: "lorem ipsum",
        },
        avatarFile: avatar,
        role,
      }).unwrap();
      // console.log(newuser);
      // const essay = await userRequest.post("/users", {
      //   email: "testeur@test.com",
      //   phone: "+212600000000",
      //   isNotif: false,
      //   isActif: true,
      //   profil: {
      //     firstname: "test",
      //     lastname: "test",
      //     birthdate: "2023-12-12",
      //     isNewsletter: false,
      //     identity: "/api/media_objects/0",
      //     identityVerso: "/api/media_objects/0",
      //     permis: "/api/media_objects/0",
      //     permisVerso: "/api/media_objects/0",
      //     carteGrise: "/api/media_objects/0",
      //     carteGriseVerso: "/api/media_objects/0",
      //     gender: "M",
      //     bio: "lorem ipsum",
      //   },
      //   avatarFile: "avatar.png",
      //   role: "Driver",
      //   plainPassword: "string",
      //   isDeleted: false,
      //   deletedAt: "2023-08-17T11:41:41.503Z",
      //   banned: false,
      // });
      // console.log(essay);
      setIsModalShow(false);
    } catch (error) {
      // console.log(error);
      toast.error(error.data["hydra:description"]);
      toast.error(ErrorToCreate);
      setIsModalShow(false);
    }
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Créer un nouveau utilisateur
        </h2>

        <TextField
          type="text"
          label="Nom"
          required
          value={fname}
          setValue={setFname}
        />
        <TextField
          type="text"
          label="Prénom"
          required
          value={lname}
          setValue={setLname}
        />

        <PhoneField label="Phone" required value={phone} setValue={setPhone} />

        <TextField
          type="email"
          label="E-mail"
          required
          value={email}
          setValue={setEmail}
        />

        <div className="relative w-full grid grid-cols-4 items-center h-fit mb-4 text-sm text-logoBlack">
          <span className="w-11/12 break-words">Rôle</span>
          <div className="col-span-3 flex space-x-12 text-sm">
            <CheckedBox
              isChecked={role === "DRIVER"}
              listener={() => setRole("DRIVER")}
              label="DRIVER"
              containerStyle="flex items-center"
            />
            <CheckedBox
              isChecked={role === "PASSENGER"}
              listener={() => setRole("PASSENGER")}
              label="PASSENGER"
              containerStyle="flex items-center"
            />
          </div>
        </div>

        <div className="relative w-full grid grid-cols-4 items-center h-fit mb-4 text-sm text-logoBlack">
          <label htmlFor="file" className="w-11/12 break-words">
            Avatar
          </label>

          <div className="relative -ml-3 col-span-3 h-[30px] px-4 py-1 bg-bgGray rounded-md">
            {avatar && (
              <span className="text-[13px] text-fontGray">{avatar.name}</span>
            )}

            <input
              onChange={(e) => setAvatar(e.target.files[0])}
              type="file"
              id="file"
              className="hidden"
            />
            <label htmlFor="file" className="w-full flex justify-end ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                className="cursor-pointer w-5 h-5 stroke-logoBlack"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>
            </label>
          </div>
        </div>
        {error && <p className="text-center text-sm text-red-400">{error}</p>}
        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={handleForm}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            {isLoading ? "loading..." : "Créer"}
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}
export default UserForm;
