import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

import {API_URL} from "../utilities/Constants"

export function useLogin() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (phone, password, isUserAcceptSession) => {
    setIsLoading(true);
    setError(null);
    // console.log(JSON.stringify({ username: phone, password }));
    const response = await fetch(API_URL+"/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: phone, password }),
    });

    const json = await response.json();

    if (!response.ok) {
      console.log(json);
      setIsLoading(false);
      setError(json.message);
    }

    if (response.ok) {
      const isAdmin = jwtDecode(json.token).roles.includes("ROLE_ADMIN"); //ROKE_SUPER_ADMIN| ROLE_SAV
      const isSuperAdmin = jwtDecode(json.token).roles.includes(
        "ROLE_SUPER_ADMIN"
      ); //ROKE_SUPER_ADMIN| ROLE_SAV
      const isSAV = jwtDecode(json.token).roles.includes("ROLE_SAV"); //ROKE_SUPER_ADMIN| ROLE_SAV
      // console.log({
      //   isAdmin,
      //   isSAV,
      //   isSuperAdmin,
      //   ex: !isAdmin || !isSAV || !isSuperAdmin,
      // });
      /*
       *
       * SAV=>MESSAGE,OFFRE,COMMANDE,NEWSLETTER,Tem| ADMIN=>Ne peux pas ajouter un SUPERADMIN,*/
      if (!(!isAdmin || !isSuperAdmin || !isSAV)) {
        setIsLoading(false);
        return setError("L'accès n'est autorisé qu'aux administrateurs");
      }
      // save the user to local storage
      isUserAcceptSession &&
        localStorage.setItem("user", JSON.stringify({ ...json }));

      // update the auth context
      dispatch({ type: "LOGIN", payload: json });
      navigate("/dashboard");
      setIsLoading(false);
    }
  };
  return { login, isLoading, error };
}
