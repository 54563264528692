import jwtDecode from "jwt-decode";
import { useContext } from "react";
import { createContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload };
    case "LOGOUT":
      return { user: null, userInfo: null };

    case "GET_USERINFO":
      return { user: state.user, userInfo: action.payload };
    default:
      return state;
  }
};

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error("useAuthContext must be used inside an AuthContextProvider");
  }
  return context;
}

export function AuthContextProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    userInfo: null,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (user) {
      dispatch({ type: "LOGIN", payload: user });
    }
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (user) {
      let isTokenExp = jwtDecode(user.token).exp * 1000 - Date.now() <= 0;
      // console.log({ isTokenExp },'token')

      let newToken;
      // if (isTokenExp) {
      //   fetch(process.env.REACT_APP_API_URL+"/token/refresh", {

      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ refresh_token: user.refresh_token }),
      //   })
      //     .then((httpRes) => httpRes.json())
      //     .then((response) => {
      //       console.log(response.json());
      //       newToken = response.json();
      //     })
      //     .catch((error) => {
      //       console.log(error)
      //       dispatch({ type: "LOGOUT" });
      //     });
      //   dispatch({ type: "LOGIN", payload: newToken });
      // }
    }
  }, [pathname]);

  // console.log("AuthContext state: ", state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}
