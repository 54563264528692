import React, { useState } from "react";
import { useModalContext } from "../../context/ModalContext";
import useCoupon from "../../hooks/useCoupon";
import { generateACoupons } from "../../utilities";
import CheckedBox from "../CheckedBox";
import TextField from "../forms_comp/TextField";
import {API_URL} from "../../utilities/Constants"

function CouponForm() {
  const { setIsModalShow } = useModalContext();
  const { error, handleForm } = useCoupon();
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [type, setType] = useState("avoir");
  const [usersNum, setUsersNum] = useState("1");
  // const [user, setUser] = useState("");
  const [percentage, setPercentage] = useState("5");
  const [motif, setMotif] = useState("");

  let url = API_URL+"/coupons";
  let method = "POST";
  // const [forAll, setForAll] = useState("all");

  async function submitChange() {
    await handleForm(
      { url, method },
      title,
      startDate,
      finalDate,
      usersNum,
      percentage,
      motif,
      type
    );
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Créer un nouveau coupons
        </h2>

        {/* <div className="flex space-x-4 mt-6 mb-4 text-sm justify-end">
        <CheckedBox
          isChecked={forAll === "all"}
          listener={() => setForAll("all")}
          label="Pour tout le monde"
          containerStyle="w-[215px] flex items-center"
        />
        <CheckedBox
          isChecked={forAll === "user"}
          listener={() => setForAll("user")}
          label="Pour un utilisateur"
          containerStyle="w-[215px] flex items-center"
        />
      </div> */}

        <div className="grid mb-1 grid-cols-4 gap-x-2">
          <TextField
            type="text"
            label="intitulé"
            required
            value={
              title.match(/^[A-Za-z0-9]*$/)
                ? title.toUpperCase()
                : title.slice(0, title.length - 1)
            }
            setValue={setTitle}
            customStyle="grid grid-cols-3 col-span-3"
            secondStyle
            maxLength={7}
          />
          <button
            onClick={() => setTitle(generateACoupons(7))}
            className="capitalize mb-4 bg-bgGray text-[13px] text-logoBlack rounded-md duration-100 ease-out hover:text-logoGreen"
          >
            générer
          </button>
        </div>

        <div className="grid grid-cols-2 gap-x-2">
          <TextField
            type="date"
            label="Date de début"
            required
            min={new Date().toISOString().split("T")[0]}
            value={startDate}
            setValue={setStartDate}
            customStyle="grid grid-cols-2"
          />
          <TextField
            type="date"
            label="Date de fin"
            required
            min={
              startDate
                ? new Date(startDate).toISOString().split("T")[0]
                : new Date().toISOString().split("T")[0]
            }
            value={finalDate}
            setValue={setFinalDate}
            customStyle="grid grid-cols-3 pl-4"
            secondStyle
          />
        </div>

        <div className="flex space-x-4 mb-4 text-sm justify-end">
          <CheckedBox
            isChecked={type === "avoir"}
            listener={() => setType("avoir")}
            label="Avoir ?"
            containerStyle="w-[215px] flex items-center"
          />
          <CheckedBox
            isChecked={type === "promo"}
            listener={() => setType("promo")}
            label="Promotion ?"
            containerStyle="w-[215px] flex items-center"
          />
        </div>
        <TextField
          type="number"
          label="Nomber d'utisation"
          required
          value={usersNum}
          setValue={setUsersNum}
          min={1}
        />
        {/* <TextField
        type="text"
        label="Renseigner l'utisateur"
        required
        value={user.split("*")[0]}
        setValue={setUser}
        disabled={forAll === "all"}
      /> */}

        <TextField
          type="number"
          label="Montant"
          required
          value={percentage}
          min={5}
          max={100}
          setValue={setPercentage}
        />
        <TextField
          type="text"
          label="Motif de création"
          required
          value={motif}
          setValue={setMotif}
        />

        {error && <p className="text-center text-sm text-red-400">{error}</p>}
        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={submitChange}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            crée
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default CouponForm;
