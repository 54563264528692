import React from "react";
import { logout } from "../redux/slices/authSlice";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { sidemenuLinks } from "../dummy";
import { LogoutIcon } from "../icons";
import { useGetUserQuery } from "../redux/apiCalls/usersApi";
import Avatar from "./Avatar";

const SideMenu = () => {
  const { logout: firstLogout } = useLogout();
  const user = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();
  const { data: userInfo } = useGetUserQuery({ id: user.id });
  const isSAV = user.roles.includes("ROLE_ADMIN");

  const logoutHandler = async () => {
    dispatch(logout({}));
    firstLogout();
  };

  return (
    <div className="w-full shrink-0 lg:w-[250px] lg:pt-6 mt-3 lg:mt-0 lg:pb-4 py-5 flex flex-row overflow-x-auto lg:overflow-x-hidden overflow-y-hidden  lg:flex-col lg:overflow-y-auto customSrollBar justify-between items-cente bg-white rounded-md lg:gap-10">
      <div className="flex items-center lg:items-start lg:flex-col lg:gap-2 shrink-0">
        {sidemenuLinks.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className={({ isActive }) =>
              `lg:w-full flex space-x-2 group shrink-0 duration-100 ease-out border-sideMenuGreen px-3 ${
                isActive
                  ? "border-l-2  stroke-sideMenuGreen text-sideMenuGreen"
                  : "hover:border-l-2 stroke-logoBlack hover:text-sideMenuGreen"
              } ${!isSAV && item.protected ? "hidden" : ""}`
            }
          >
            <item.icon
              width={18}
              color="white"
              className={
                "shrink-0 stroke-inherit group-hover:stroke-sideMenuGreen"
              }
            />
            <span>{item.name}</span>
          </NavLink>
        ))}
      </div>
      {/* logout */}
      <div className="shrink-0 px-3 lg:space-y-5 overflow-hidden">
        <div className="hidden lg:flex items-center gap-2 w-full cursor-pointer">
          <Avatar
            photo={userInfo?.avatarUrl}
            fName={userInfo?.profil.firstname}
            lName={userInfo?.profil.lastname}
          />
        </div>

        <div
          className="flex gap-2 cursor-pointer  hover:text-sideMenuGreen group"
          onClick={logoutHandler}
        >
          <LogoutIcon
            width={25}
            color={"white"}
            className={"stroke-logoBlack group-hover:stroke-sideMenuGreen"}
          />
          <span className="font-semibold">Déconnexion</span>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
