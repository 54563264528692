import { useState } from "react";
import { useModalContext } from "../../context/ModalContext";
import useCoupon from "../../hooks/useCoupon";
import { ArchiveIcon, EditIcon2 } from "../../icons";
import CustomRow from "../CustomRow";
import TextField from "../forms_comp/TextField";
import {API_URL} from "../../utilities/Constants"

function CouponInfo({ couponInfo }) {
  const [isInputReadOnly, setIsInputReadOnly] = useState(true);
  const [title, setTitle] = useState(couponInfo.code);
  const [startDate, setStartDate] = useState(couponInfo.startDate);
  const [finalDate, setFinalDate] = useState(couponInfo.endDate);
  const [status, setStatus] = useState(couponInfo.status);
  const [usersNum, setUserNum] = useState(couponInfo.amount + "");
  const [motif, setMotif] = useState(couponInfo.motif);
  const [percentage, setPercentage] = useState(couponInfo.percentage + "");

  const { setIsModalShow, setFetchAgain, fetchAgain } = useModalContext();
  const { error, handleForm, removeCoupon } = useCoupon();

  let url = API_URL+`/coupons/${couponInfo.id}`;
  // const [forAll, setForAll] = useState("all");

  async function archiveCoupon() {
    if (isInputReadOnly) return;
    await handleForm(
      { url, method: "PUT" },
      title,
      startDate,
      finalDate,
      usersNum,
      percentage,
      motif,
      "promotion"
    );
    setFetchAgain(!fetchAgain);
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        <div className="flex mb-6 items-center space-x-4">
          <svg
            onClick={() => setIsModalShow(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            className="w-8 h-8 cursor-pointer duration-100 ease-in hover:bg-bgGray/50 stroke-logoBlack"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <h2 className="w-full text-logoBlack font-medium text-center">
            Coupons information
          </h2>
        </div>

        <TextField
          type="text"
          label="Intitulé:"
          value={
            title.match(/^[A-Za-z0-9]*$/)
              ? title.toUpperCase()
              : title.slice(0, title.length - 1)
          }
          setValue={setTitle}
          readonly={isInputReadOnly}
        />

        <TextField
          type="date"
          label="Date de début:"
          min={new Date().toISOString().split("T")[0]}
          value={startDate}
          setValue={setStartDate}
          readonly={isInputReadOnly}
        />

        <TextField
          type="date"
          label="Date de fin:"
          min={new Date(startDate).toISOString().split("T")[0]}
          value={finalDate}
          setValue={setFinalDate}
          readonly={isInputReadOnly}
        />

        <TextField
          type="text"
          label="status:"
          value={status}
          setValue={setStatus}
          readonly={isInputReadOnly}
        />

        <TextField
          type="text"
          label="Nomber utilisation:"
          value={usersNum}
          setValue={setUserNum}
          readonly={isInputReadOnly}
        />

        <TextField
          type="text"
          label="Motif:"
          value={motif}
          setValue={setMotif}
          readonly={isInputReadOnly}
        />
        <TextField
          type="text"
          label="Pourcentage:"
          value={percentage}
          setValue={setPercentage}
          readonly={isInputReadOnly}
        />
        {error && <p className="text-center text-sm text-red-400">{error}</p>}

        <div className="pt-4 flex justify-end">
          <CustomRow
            textStyle="text-[10px]"
            containerStyle="group bg-bgGray p-1 rounded-md cursor-pointer ml-2"
            listener={() => setIsInputReadOnly(false)}
          >
            <EditIcon2
              width={18}
              className="group-hover:stroke-logoGreen stroke-logoBlack fill-bgGray stroke-2"
            />
          </CustomRow>
          <CustomRow
            textStyle="text-[10px]"
            containerStyle="group bg-bgGray p-1 rounded-md cursor-pointer ml-2"
            listener={archiveCoupon}
          >
            <ArchiveIcon
              width={18}
              className="group-hover:stroke-logoGreen stroke-logoBlack fill-bgGray stroke-2"
            />
          </CustomRow>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="stroke-logoBlack duration-100 ease-in hover:stroke-logoGreen ml-2 bg-bgGray p-1 rounded-md cursor-pointer"
            width={26}
            onClick={async () => {
              await removeCoupon(url);
              setFetchAgain(!fetchAgain);
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default CouponInfo;
