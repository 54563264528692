import { apiSlice } from "../slices/apiSlice";

export const newslettersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNewsletters: builder.query({
      query: () => "/newsletters",
      providesTags: ["Newsletters"],
    }),

    creatNews: builder.mutation({
      query: (data) => ({
        url: "/newsletters",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Newsletters"],
    }),

    deleteNews: builder.mutation({
      query(id) {
        return {
          url: `/newsletters/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Newsletters"],
    }),

    updateNews: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `/newsletters/${id}`,
          method: "PATCH",
          headers: {
            "content-type": "application/merge-patch+json",
          },
          body,
        };
      },
      invalidatesTags: ["Newsletters"],
    }),
  }),
});

export const {
  useGetNewslettersQuery,
  useCreatNewsMutation,
  useDeleteNewsMutation,
  useUpdateNewsMutation,
} = newslettersApi;
