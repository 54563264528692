import { apiSlice } from "../slices/apiSlice";

export const paymentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: () => "/payments",
      providesTags: ["Payment"],
    }),

    getModePayments: builder.query({
      query: () => "/mode_payments",
      providesTags: ["Mode_payement"],
    }),

    creatPaymentMode: builder.mutation({
      query: (data) => ({
        url: "/mode_payments",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Mode_payement"],
    }),

    deletePaymentMode: builder.mutation({
      query(id) {
        return {
          url: `/mode_payments/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Mode_payement"],
    }),

    updatePaymentMode: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `/mode_payments/${id}`,
          method: "PATCH",
          headers: {
            "content-type": "application/merge-patch+json",
          },
          body,
        };
      },
      invalidatesTags: ["Mode_payement"],
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useGetModePaymentsQuery,
  useCreatPaymentModeMutation,
  useDeletePaymentModeMutation,
  useUpdatePaymentModeMutation,
} = paymentsApi;
