import { useState } from "react";
import { useModalContext } from "../../context/ModalContext";
import TextField from "../forms_comp/TextField";
import CheckedBox from "../CheckedBox";
import {API_URL} from "../../utilities/Constants"

function CityFormEdit({ CityInfo }) {
  const { setIsModalShow, setFetchAgain, fetchAgain } = useModalContext();
  const [city, setCity] = useState(CityInfo.city);
  const [long, setLong] = useState(CityInfo.lng);
  const [lat, setLat] = useState(CityInfo.lat);
  const [file, setFile] = useState("");
  const [error, setError] = useState(false);
  const [isValide, setIsValide] = useState(CityInfo.isValide);

  async function handleForm() {
    if (!city || !long || !lat) {
      setError("Tous les champs sont obligatiores");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    let httpRes = await fetch(
      API_URL+`/villes/${CityInfo.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
        body: JSON.stringify({
          adress: CityInfo.adress,
          city,
          lng: Number(long),
          lat: Number(lat),
          postalCode: CityInfo.postalCode,
          isValide,
        }),
      }
    );


    let data = await httpRes.json();

    if (httpRes.ok) {
      setIsModalShow(false);
      setFetchAgain(!fetchAgain);
      window.location.reload();
    } else {
      console.log(data);
    }
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Edit une ville
        </h2>

        <TextField
          type="text"
          label="Ville"
          required
          value={city.split("*")[0]}
          setValue={setCity}
        />

        <TextField
          type="text"
          label="Longitude"
          required
          value={long}
          setValue={setLong}
        />

        <TextField
          type="text"
          label="Latitude"
          required
          value={lat}
          setValue={setLat}
        />

        <div className="relative w-full grid grid-cols-4 items-center h-fit mb-4 text-sm text-logoBlack">
          <label htmlFor="file" className="w-11/12 break-words">
            Ajouter une photo
          </label>

          <div className="relative -ml-3 col-span-3 h-[30px] px-4 py-1 bg-bgGray rounded-md">
            {file && (
              <span className="text-[13px] text-fontGray">{file.name}</span>
            )}

            <input
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              id="file"
              className="w-0 h-0 absolute top-0 left-0 -z-10"
            />
            <label htmlFor="file" className="w-fit float-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                className="cursor-pointer w-5 h-5 stroke-logoBlack"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>
            </label>
          </div>
        </div>
        <div className="w-full grid grid-cols-3 h-fit mb-4 text-sm text-logoBlack">
          <label className="w-11/12 break-words">Status</label>
          <CheckedBox
            label="ACTIVE"
            containerStyle="flex items-center"
            isChecked={isValide === true}
            listener={() => setIsValide(true)}
          />
          <CheckedBox
            label="NON ACTIVE"
            containerStyle="flex items-center"
            isChecked={isValide === false}
            listener={() => setIsValide(false)}
          />
        </div>
        {error && <p className="text-center text-sm text-red-400">{error}</p>}
        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={handleForm}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            Update
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default CityFormEdit;
