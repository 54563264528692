import {  publicRequest } from "../requestMethods";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from "../redux/slices/authSlice";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const Refresh_Token = user.refresh_token;
  // console.log(Refresh_Token);

  const refresh = async () => {
    try {
      const res = await publicRequest.post("/token/refresh", {
        refresh_token: Refresh_Token,
        // withCredentials: true,
      });
      // console.log(res.data,"resp");
      localStorage.setItem("newToken", JSON.stringify(res.data.token));
      localStorage.setItem(
        "refresh_Token",
        JSON.stringify(res.data.refresh_token)
      );
      return res.data.token;
    } catch (error) {
      console.log("error refresh token", error);
      if (error.response.data.error === "Refresh token not found") {
        dispatch(logout());
        navigate("/");
      }
    }
  };
  refresh()
  
  return refresh;
};

export default useRefreshToken;


// Expired JWT Token