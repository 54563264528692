import { apiSlice } from "../slices/apiSlice";

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (args) => {
        const { slug } = args;
        return {
          url: `${slug}`,
        };
      },
      providesTags: ["Users"],
    }),

    test: builder.query({
      query: (args) => {
        const { type, id } = args;
        return {
          url: `${type}/${id}`,
        };
      },
      providesTags: ["Users"],
    }),

    getUser: builder.query({
      query: (args) => {
        const { id } = args;
        return {
          url: `/users/${id}`,
        };
      },
      providesTags: ["Users"],
    }),

    creatUser: builder.mutation({
      query: (data) => ({
        url: "/users",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    
    deleteUser: builder.mutation({
      query(id) {
        return {
          url: `/users/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useTestQuery,
  useCreatUserMutation,
  useDeleteUserMutation
} = usersApi;
