import { useModalContext } from "../../context/ModalContext";
import TextField from "../forms_comp/TextField";

function OptionInfo({ optionInfo }) {
  const { setIsModalShow } = useModalContext();

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        
        <div className="flex mb-6 items-center space-x-4">
          <svg
            onClick={() => setIsModalShow(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            className="w-8 h-8 cursor-pointer duration-100 ease-in hover:bg-bgGray/50 stroke-logoBlack"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>

          <h2 className="w-full text-logoBlack font-medium text-center">
            Temoignage
          </h2>
        </div>

        <TextField
          type="text"
          label="fullname"
          required
          disabled
          value={optionInfo?.fullname}
        />
        <TextField
          type="text"
          label="description"
          required
          disabled
          value={optionInfo?.description}
        />
        <TextField
          type="text"
          label="status"
          required
          disabled
          value={optionInfo?.status}
        />
        <TextField
          type="text"
          label="rate"
          required
          disabled
          value={optionInfo?.rate}
        />
      </div>
    </div>
  );
}
export default OptionInfo;
