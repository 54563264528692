import { apiSlice } from "../slices/apiSlice";

export const offersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query({
      query: () => "/offers?sort=updatedAt",
      providesTags: ["Offers"],
    }),
  }),
});

export const { useGetOffersQuery } = offersApi;
