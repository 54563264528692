import { useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useModalContext } from "../../context/ModalContext";
import PhoneField from "../forms_comp/PhoneField";
import TextField from "../forms_comp/TextField";
import {API_BASE_URL} from "../../utilities/Constants"

const CancelOffer = ({ commandInfo }) => {
  const { setIsModalShow } = useModalContext();
  const [departD] = useState(
    new Date(commandInfo.departDate).toLocaleString("fr", {
      timeStyle: "medium",
    })
  );
  const [departedCity] = useState(commandInfo.villeDepart.city);
  const [arrivedCity] = useState(commandInfo.villeArrive.city);

  const [name] = useState(
    commandInfo.user.profil.firstname + commandInfo.user.profil.lastname
  );
  const [phone] = useState(commandInfo.user.phone);
  const [places] = useState(commandInfo.nbrePlaces);
  const [error, setError] = useState(false);

  const { user } = useAuthContext();

  async function disableOffer() {
    let httpRes = await fetch(
      API_BASE_URL+`${commandInfo["@id"]}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          status: "Canceled",
        }),
      }
    );

    let data = await httpRes.json();

    if (httpRes.ok) {
      setIsModalShow(false);
    } else {
      console.log(data);
      setError(data["hydra:description"]);
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
    }
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Désactive cette offre
        </h2>

        <TextField
          type="text"
          label="Utilisateurs"
          value={name}
          // setValue={setName}
          readonly={true}
        />

        <PhoneField
          readonly={true}
          type="tel"
          label="Phone"
          value={phone}
          // setValue={setPhone}
        />

        <TextField
          type="text"
          label="Date de départ"
          required
          value={departD}
          // setValue={setDepartD}
          readonly={true}
        />

        <TextField
          type="text"
          label="Ville de départ"
          value={departedCity}
          // setValue={setDepartedCity}
          readonly={true}
        />
        <TextField
          type="text"
          label="Ville d'arrivée"
          value={arrivedCity}
          // setValue={setArrivedCity}
          readonly={true}
        />

        <TextField
          type="number"
          label="N° de place dispo"
          min={1}
          value={places}
          // setValue={setPlaces}
          readonly={true}
        />

        {error && <p className="text-center text-sm text-red-400">{error}</p>}
        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={disableOffer}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            désactiver
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelOffer;
