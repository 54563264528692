import { apiSlice } from "../slices/apiSlice";

export const optionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOptions: builder.query({
      query: () => "/options",
      providesTags: ["Options"],
    }),

    creatOption: builder.mutation({
      query: (data) => ({
        url: "/options",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Options"],
    }),

    deleteOption: builder.mutation({
      query(id) {
        return {
          url: `/options/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Options"],
    }),

    updateOption: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `/options/${id}`,
          method: "PATCH",
          headers: {
            "content-type": "application/merge-patch+json",
          },
          body,
        };
      },
      invalidatesTags: ["Options"],
    }),
  }),
});

export const {
  useGetOptionsQuery,
  useCreatOptionMutation,
  useDeleteOptionMutation,
  useUpdateOptionMutation,
} = optionsApi;
