import { useModalContext } from "../../context/ModalContext";
import {API_BASE_URL} from "../../utilities/Constants"

function PaperInfo({ fileSrc }) {
  const { setIsModalShow } = useModalContext();
  
  return (
    <div className="relative mt-4 w-4/5 h-[calc(100%-50px)] mx-auto">
      <svg
        onClick={() => setIsModalShow(false)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        className="absolute -top-10 right-0 w-10 h-10 cursor-pointer duration-100 ease-in hover:bg-bgGray/50 stroke-logoBlack"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>

      <div className="bg-white h-full overflow-y-auto customSrollBar">
        {fileSrc.map((item) =>
          item.filePath.split(".")[item.filePath.split(".").length - 1] !==
          "pdf" ? (
            <img
              className="w-3/4 mx-auto mb-5"
              key={item.contentUrl}
              src={API_BASE_URL + item.contentUrl}
              alt="identity"
            />
          ) : (
            <iframe
              key={item.contentUrl}
              className="w-11/12 h-full mx-auto mb-8"
              title="doc"
              src={API_BASE_URL + item.contentUrl}
            ></iframe>
          )
        )}
      </div>
    </div>
  );
}

export default PaperInfo;
