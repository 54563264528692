import React from "react";
import {API_BASE_URL} from "../utilities/Constants"

const Avatar = ({ photo, fName, lName }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="w-10 h-10 rounded-full overflow-hidden">
        <img
          src={
            photo
              ? API_BASE_URL + photo
              : "/assets/avatar.png"
          }
          alt=""
          className="w-full object-cover"
        />
      </div>
      <div className="grid">
        <span>{fName}</span>
        <span>{lName}</span>
      </div>
    </div>
  );
};

export default Avatar;
