import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../context/ModalContext";
import TextField from "../forms_comp/TextField";
import { useCreatPaymentModeMutation } from "../../redux/apiCalls/paymentsApi";
import { toast } from "react-toastify";

function OptionForm() {
  const { setIsModalShow } = useModalContext();
  const [methodeName, setMethodeName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const [createHandler] = useCreatPaymentModeMutation();

  const navigate = useNavigate();

  async function handleForm(_e) {
    if (!methodeName || !description) {
      setError("Tous les champs sont obligatiores");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    try {
      await createHandler({
        name: methodeName,
        description: description,
      }).unwrap();
      navigate("/modepayements");
      setIsModalShow(false);
      toast.success("Payments methode created successful");
    } catch (error) {
      console.log(error);
      toast.error(error.status);
    }
  }

  return (
    <div className="absolute p-4 left-1/2 -translate-x-[calc(50%-50px)] top-20 mx-auto h-fit w-[45%] bg-white drop-shadow rounded-lg">
      <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
        Methode de payement
      </h2>

      <TextField
        type="text"
        label="nom"
        required
        value={methodeName}
        setValue={setMethodeName}
      />
      <TextField
        type="text"
        label="description"
        required
        value={description}
        setValue={setDescription}
      />
      {error && <p className="text-center text-sm text-red-400">{error}</p>}
      <div className="flex space-x-4 mt-6 text-sm justify-end">
        <button
          onClick={handleForm}
          className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
        >
          crée
        </button>
        <button
          onClick={() => setIsModalShow(false)}
          className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
        >
          annuler
        </button>
      </div>
    </div>
  );
}
export default OptionForm;
