import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { useAuthContext } from "./context/AuthContext";
import { useModalContext } from "./context/ModalContext";
import BoardTable from "./menu/BoardTable";
import LoginPage from "./pages/LoginPage";
import ForgotPassword from "./pages/ForgotPassword";
import ConfirmNewPassword from "./pages/ConfirmNewPassword";
import { decodeJWT } from "./utilities";
import { useSelector } from "react-redux";
import ProtectedRoute from "./layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// users
const UsersAcounts = lazy(() => import("./menu/users/UsersAcounts"));
const UserInfos = lazy(() => import("./menu/users/UserInfos"));
const PersonalInfos = lazy(() => import("./menu/users/PersonalInfos"));
const UserPapers = lazy(() => import("./menu/users/UserPapers"));
const CommadsDetails = lazy(() => import("./menu/users/CommadsDetails"));
const Beneficiaries = lazy(() => import("./menu/users/Beneficiaries"));
const UserVehicules = lazy(() => import("./menu/users/UserVehicules"));
const UserPayments = lazy(() => import("./menu/users/UserPayments"));
// const Valid = lazy(() => import("./menu/Valid"));
//messages
const Messages = lazy(() => import("./menu/Messages"));
// commands
const Commands = lazy(() => import("./menu/commands/Commands"));
const CreateCommand = lazy(() => import("./menu/commands/CreateCommand"));
// offers
const Offers = lazy(() => import("./menu/offers/Offers"));
const CreateOffer = lazy(() => import("./menu/offers/CreateOffers"));

// coupons
const Coupons = lazy(() => import("./menu/coupons/Coupons"));
const CreateCoupon = lazy(() => import("./menu/coupons/CreateCoupon"));
const Payments = lazy(() => import("./menu/Payments"));
const Vehicles = lazy(() => import("./menu/Vehicles"));
// cities
const Cities = lazy(() => import("./menu/cities/Cities"));
const CreateCity = lazy(() => import("./menu/cities/CreateCity"));

const Admins = lazy(() => import("./menu/admins/Admins"));
const CreateAdmin = lazy(() => import("./menu/admins/CreateAdmin"));

const Options = lazy(() => import("./menu/options/Options"));
const CreateOption = lazy(() => import("./menu/options/CreateOption"));
//            <Route path="insert_code" element={<InsertVerificationCode />} />*/

const ModePayement = lazy(() => import("./menu/modePayements/ModePayements"));
const CreateModePayement = lazy(() =>
  import("./menu/modePayements/CreateMethodePayement")
);

const Newsletter = lazy(() => import("./menu/NewsLetter/Newsletter"));
const AddUserNewsletter = lazy(() =>
  import("./menu/NewsLetter/AddUserToNewsletter")
);

const Temoignage = lazy(() => import("./menu/Temoignage/Temoignage"));
const CreateTemoignage = lazy(() =>
  import("./menu/Temoignage/CreateTemoignage")
);

function App() {
  // const { component, isModalShow } = useModalContext();
  // const { userInfo } = useSelector((state) => state.auth);
  // console.log("🚀userInfo:", userInfo);
  // const token = localStorage.getItem("newToken");
  // console.log(token);

  // let isSAV = false;

  // if (userInfo !== null) {
  //   isSAV = decodeJWT(userInfo.token).roles.includes("ROLE_SAV") || false;
  // }
  // console.log(isSAV);
  // console.log({ user: decodeJWT(user?.token) })
  // const isSAV = jwtDecolde(user.token).roles.includes("ROLE_SAV") || false;
  return (
    <>
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="/confirm_password/*" element={<ConfirmNewPassword />} />
        <Route path="" element={<ProtectedRoute />}>
          <Route index path="/dashboard" element={<BoardTable />} />
          <Route path="/users">
            <Route index element={<UsersAcounts />} />
            <Route path=":userId" element={<UserInfos />}>
              <Route index element={<PersonalInfos />} />
              <Route path="papers" element={<UserPapers />} />
              <Route
                path="reservations"
                element={<CommadsDetails isOffers={false} />}
              />
              <Route path="beneficiaries" element={<Beneficiaries />} />
              <Route
                path="offers"
                element={<CommadsDetails isOffers={true} />}
              />
              <Route path="vehicles" element={<UserVehicules />} />
              <Route path="payments" element={<UserPayments />} />
            </Route>
          </Route>
          {/* <Route path="valid" element={<Valid />} /> */}
          <Route path="/messages" element={<Messages />} />
          <Route path="/commands">
            <Route index element={<Commands />} />
            <Route path="create" element={<CreateCommand />} />
          </Route>
          <Route path="/offers">
            <Route index element={<Offers />} />
            <Route path="create" element={<CreateOffer />} />
          </Route>
          <Route path="/coupons">
            <Route index element={<Coupons />} />
            <Route path="create" element={<CreateCoupon />} />
          </Route>
          <Route path="/payments" element={<Payments />} />
          <Route path="/vehicules" element={<Vehicles />} />
          <Route path="/cities">
            <Route index element={<Cities />} />
            <Route path="create" element={<CreateCity />} />
          </Route>
          <Route path="/admins">
            <Route index element={<Admins />} />
            <Route path="create" element={<CreateAdmin />} />
          </Route>
          <Route path="/options">
            <Route index element={<Options />} />
            <Route path="create" element={<CreateOption />} />
          </Route>
          <Route path="/modepayements">
            <Route index element={<ModePayement />} />
            <Route path="create" element={<CreateModePayement />} />
          </Route>
          <Route path="/newsletter">
            <Route index element={<Newsletter />} />
            <Route path="create" element={<AddUserNewsletter />} />
          </Route>
          <Route path="/temoignage">
            <Route index element={<Temoignage />} />
            <Route path="create" element={<CreateTemoignage />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        theme="dark"
        closeOnClick
        position="top-right"
        autoClose={5000}
      />
    </>
  );
}

export default App;
