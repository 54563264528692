import CustomHeader from "../components/CustomHeader";
import CustomRow from "../components/CustomRow";
import LastComandsRow from "../components/boardTable_comp/LastComandsRow";
import {
  CommandesIcon,
  CommandsCanceledIcon,
  CommandsPayedByCashIcon,
  CommandsReqIcon,
  OffresCanceledIcon,
  OffresIcon,
  OffresSharedIcon,
  TransactionIcon,
  UsersArchiveIcon,
} from "../icons";
import LastPayments from "../components/boardTable_comp/LastPayments";
import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import {
  useGetCommandsQuery,
  useGetNextCommandsQuery,
} from "../redux/apiCalls/bookingApi";
import { useGetOffersQuery } from "../redux/apiCalls/offersApi";
import { useGetCommissionQuery } from "../redux/apiCalls/commissionsApi";
import useRefreshToken from "../hooks/useRefreshToken";

const BoardTable = () => {
  const { data: booking, isLoading: bookingLoad } = useGetCommandsQuery();
  const { data: nextBooking, isLoading: nextbookLoad } =
    useGetNextCommandsQuery();
  const { data: offer, isLoading: offerLoad } = useGetOffersQuery();
  const { data: commission, isLoading: commissionLoad } =
    useGetCommissionQuery();

  const navigate = useNavigate();
  let offrePublished = 0;
  let offreCanceled = 0;
  let offreTerminated = 0;
  let bookingCanceled = 0;
  let bookingprice = [];
  let commissionprice = [];

  // eslint-disable-next-line no-unused-expressions
  offer
    ? (offrePublished = [...offer["hydra:member"]].filter(
        (el) => el.status === "Publié"
      ).length)
    : null;

  // eslint-disable-next-line no-unused-expressions
  offer
    ? (offreCanceled = [...offer["hydra:member"]].filter(
        (el) => el.status === "Annulé"
      ).length)
    : null;
  // eslint-disable-next-line no-unused-expressions
  offer
    ? (offreTerminated = [...offer["hydra:member"]].filter(
        (el) => el.status === "Draft"
      ).length)
    : null;

  // eslint-disable-next-line no-unused-expressions
  booking
    ? (bookingCanceled = [...booking["hydra:member"]].filter(
        (el) => el.status === "Annulé"
      ).length)
    : null;

  // eslint-disable-next-line no-unused-expressions
  booking
    ? (bookingprice = [...booking["hydra:member"]].map(
        (el) => el.payment?.montant
      ))
    : null;
  const TotalchiffreAffaire = bookingprice.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  // eslint-disable-next-line no-unused-expressions
  commission
    ? (commissionprice = [...commission["hydra:member"]].map(
        (el) => el?.montant
      ))
    : null;
  const TotalCommision = commissionprice.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  return (
    <div className="relative px-4 py-4 pb-8 h-full overflow-y-auto customSrollBar">
      <CustomHeader title="Tableau de bord" />{" "}
      {!offerLoad && !bookingLoad && !nextbookLoad && !commissionLoad ? (
        <>
          <div className="my-6 flex flex-wrap gap-5 items-center ">
            {/* all offers */}
            <CustomRow
              description={offer["hydra:totalItems"]}
              textStyle="w-fit  text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Total offers"
              underStyle="text-xs font-medium"
            >
              <OffresIcon
                // width={22}
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total published offers */}
            <CustomRow
              description={offrePublished}
              textStyle="w-fit   text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Offre publié"
              underStyle="text-xs font-medium"
            >
              <OffresSharedIcon
                // width={22}
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total canceled offers */}
            <CustomRow
              description={offreCanceled}
              textStyle="w-fit text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Offres annulées"
              underStyle="text-xs font-medium"
            >
              <OffresCanceledIcon
                // width={22}
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total archived offers */}
            <CustomRow
              description={offreTerminated}
              textStyle="w-fit text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Offres archivé"
              underStyle="text-xs font-medium"
            >
              <UsersArchiveIcon
                // width={22}
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total chiffre d'affaire */}
            <CustomRow
              description={TotalchiffreAffaire.toLocaleString("fr-ca", {
                style: "currency",
                currency: "FGN",
              })}
              textStyle="w-fit max-w-[200px]  text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Total Chiffre d'affaire"
              underStyle="text-xs font-medium"
            >
              <TransactionIcon
                // width={22}
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total commandes */}
            <CustomRow
              description={booking["hydra:totalItems"]}
              textStyle="w-fit text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Total Commandes"
              underStyle="text-xs font-medium"
            >
              <CommandesIcon
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total Commandes à venir offers */}
            <CustomRow
              description={nextBooking["hydra:totalItems"]}
              textStyle="w-fit  text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Commandes à venir"
              underStyle="text-xs font-medium"
            >
              <CommandsReqIcon
                // width={22}
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total Commandes annulées */}
            <CustomRow
              description={bookingCanceled}
              textStyle="w-  text-logoBlack font-semibold leading-5"
              containerStyle="w-fit shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Commandes annulées"
              underStyle="text-xs font-medium"
            >
              <CommandsCanceledIcon
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total Commandes espèces */}
            <CustomRow
              description="567"
              textStyle="w-fit  text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Commandes espèces"
              underStyle="text-xs font-medium"
            >
              <CommandsPayedByCashIcon
                // width={22}
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
            {/* total Commissions */}
            <CustomRow
              description={TotalCommision.toLocaleString("fr-ca", {
                style: "currency",
                currency: "FGN",
              })}
              textStyle="w-fit max-w-[200px]  text-logoBlack font-semibold leading-5"
              containerStyle="w- shrink-0 relative bg-bgGray/50 rounded-md p-3 space-x-3"
              underTitle="Total Commissions"
              underStyle="text-xs font-medium"
            >
              <TransactionIcon
                // width={22}
                width={35}
                className="stroke-logoBlack shrink-0 lea font-medium fill-none stroke-[1.5]"
              />
            </CustomRow>
          </div>

          <div className="flex flex-col gap-5">
            {/* dernier commandes */}
            <div className="relative bg-bgGray/50 rounded-md">
              {/* head title */}
              <div className="flex justify-between items-center p-3">
                <h3 className="text-sm w-fit text-logoBlack font-semibold">
                  Dernier Commandes
                </h3>

                <p
                  onClick={() => navigate("/commands")}
                  className="text-[12px] text-fontColor cursor-pointer duration-100 hover:text-logoGreen"
                >
                  Voir plus &gt;
                </p>
              </div>
              {/* booking details */}
              <div className="max-h-[280px] customSrollBar overflow-y-auto">
                {booking["hydra:member"] ? (
                  booking["hydra:member"]?.map((command) => (
                    <LastComandsRow key={command.id} infos={command} />
                  ))
                ) : (
                  <div className="p-3 text-center">
                    <span>Pas de commande</span>
                  </div>
                )}
              </div>
              <div className="bg-white blur-lg absolute w-full h-8 bottom-0" />
            </div>

            {/* dernier payement */}
            <div className="relative bg-bgGray/50 rounded-md">
              {/* head title */}
              <div className="flex justify-between items-center p-2">
                <h3 className="text-sm w-fit text-logoBlack font-semibold">
                  Dernier Payement
                </h3>

                <p
                  onClick={() => navigate("/payments")}
                  className="text-[12px] text-fontColor cursor-pointer duration-100 hover:text-logoGreen"
                >
                  Voir plus &gt;
                </p>
              </div>
              {/* payements details */}
              <div className="max-h-[280px] customSrollBar overflow-y-auto">
                {booking["hydra:member"] ? (
                  booking["hydra:member"].map((command) => (
                    <LastPayments key={command.id} data={command} />
                  ))
                ) : (
                  <div className="p-3 text-center">
                    <span>Pas de payement</span>
                  </div>
                )}
              </div>
              <div className="bg-white blur-lg absolute w-full h-8 bottom-0" />
            </div>
          </div>
        </>
      ) : (
        <div className="w-full h-full flex justify-center items-center p-5">
          Loading...
        </div>
      )}
    </div>
  );
};

export default BoardTable;
