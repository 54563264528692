import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../context/ModalContext";

function useCoupon() {
  const { setIsModalShow } = useModalContext();

  const [error, setError] = useState(false);

  const navigate = useNavigate();

  async function handleForm(
    reqData,
    title,
    startDate,
    finalDate,
    usersNum,
    percentage,
    motif,
    type
  ) {
    if (!title || !startDate || !finalDate || !motif) {
      setError("Tous les champs sont obligatiores");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    if (
      !usersNum.match(/^[0-9]*$/) ||
      !percentage.match(/^[0-9]*$/) ||
      usersNum <= 0 ||
      percentage <= 0 ||
      percentage > 100
    ) {
      usersNum <= 0
        ? setError(
            "Le nomber d'utilisation doit être grand de zero et valide numéro"
          )
        : setError("Le percentage doit être un numéro entre 1 et 100");

      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    // if (forAll === "user" && !user) {
    //   setError("Le champ reseigner l'utilisateur doit être rempli!");
    //   let timer = setTimeout(() => {
    //     setError(false);
    //     clearTimeout(timer);
    //   }, 5000);
    //   return;
    // }

    let httpRes = await fetch(reqData.url, {
      method: reqData.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: title.match(/^[A-Za-z0-9]*$/)
          ? title.toUpperCase()
          : title.slice(0, title.length - 1),
        amount: Number(usersNum),
        startDate: new Date(startDate).toLocaleString("fr-CA", {
          dateStyle: "short",
        }),
        endDate: new Date(finalDate).toLocaleString("fr-CA", {
          dateStyle: "short",
        }),
        status: "actif",
        type,
        description: "description12",
        percentage: Number(percentage),
        motif,
        createdAt: new Date().toLocaleString("fr-CA", {
          dateStyle: "short",
        }),
      }),
    });

    let data = await httpRes.json();

    if (httpRes.ok) {
      navigate("/coupons");
      setIsModalShow(false);
      // console.log(data);
    } else {
      console.log(data);
    }
  }

  async function removeCoupon(url) {
    let httpRes = await fetch(url, {
      method: "DELETE",
    });

    if (httpRes.ok) {
      navigate("/coupons");
      setIsModalShow(false);
    } else {
        console.log(httpRes)
    }
  }

  return { error, handleForm, removeCoupon };
}

export default useCoupon;
