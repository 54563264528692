import { useState } from "react";
import { useModalContext } from "../../context/ModalContext";

function CityImport() {
  const [file, setFile] = useState("");
  const { setIsModalShow } = useModalContext();
  const [error, setError] = useState(null);

  function printData() {
    if (!file || file.type !== "application/json") {
      setError("Il n'accepte que les fichiers JSON");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }
  }
  async function handleForm() {
    setIsModalShow(false);
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        
        <h2 className="mb-8 text-logoBlack font-medium text-center">
          Importer nouvelles villes
        </h2>

        <div className="relative gap-x-4 w-full grid grid-cols-6 items-center h-fit mb-4 text-sm text-logoBlack">
          <label htmlFor="file" className="w-11/12 break-words">
            Importer les villes
          </label>

          <div className="relative -ml-3 col-span-4 h-[30px] px-4 py-1 bg-bgGray rounded-md">
            {file && (
              <span className="text-[13px] text-fontGray">{file.name}</span>
            )}

            <input
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              id="file"
              className="w-0 h-0 absolute top-0 left-0 -z-10"
            />
            <label htmlFor="file" className="w-fit float-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                className="cursor-pointer w-5 h-5 stroke-logoBlack"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>
            </label>
          </div>
          <button
            onClick={printData}
            className="w-fit px-4 py-[5px] border bg-bgGray duration-100 ease-linear hover:border-logoGreen rounded-md"
          >
            Voir
          </button>
        </div>

        {error && <p className="text-center text-sm text-red-400">{error}</p>}

        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={handleForm}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            importer
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 hover:bg-blue-300/70 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default CityImport;
