import { useEffect, useRef, useState } from "react";
import { DropIcon } from "../icons";

function SuggestionField({ suggestions, setValue, isNormal }) {
  const [dropDown, setDropDown] = useState(false);
  const dropDownList = useRef(null);
  const [suggId, setSuggId] = useState(0);

  useEffect(() => {
    setValue(suggestions[suggId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [suggId]);

  useEffect(() => {
    window.addEventListener("click", showDropDown);

    return () => {
      window.removeEventListener("click", showDropDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function showDropDown(e) {
    if (e.target !== dropDownList.current) {
      setDropDown(false);
    } else {
      setDropDown((prev) => !prev);
    }
  }

  return (
    <div
      ref={dropDownList}
      className="relative cursor-pointer text-logoBlack text-sm w-[440px] ml-[calc(100%-440px)] my-4"
    >
      <div className="pointer-events-none flex justify-between items-center border-b pl-1">
        {isNormal ? (
          <span>{suggestions[suggId]}</span>
        ) : (
          <>
            <span>
              {suggestions[suggId].villeDepart.city +
                " - " +
                suggestions[suggId].villeArrive.city}
            </span>
            <span>
              {new Date(suggestions[suggId].departDate).toLocaleString("fr", {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </span>
            <span>
              Places disponible:
              {suggestions[suggId].nbrePlacesDispo}
            </span>{" "}
          </>
        )}

        {dropDown ? (
          <DropIcon width={30} className="rotate-180" color="#21163A" />
        ) : (
          <DropIcon width={30} color="#21163A" />
        )}
      </div>

      <ul
        className={`absolute z-10 bottom-full left-0 bg-white border-x border-b h-[180px] overflow-y-auto customSrollBar w-full transition duration-500 ${
          dropDown ? "visible opacity-100" : "invisible opacity-0"
        }`}
      >
        {suggestions.map((item, id) => (
          <li
            key={id}
            onClick={() => setSuggId(id)}
            className="p-2 cursor-pointer transition duration-500 ease-out hover:bg-fontColor/20"
          >
            {isNormal ? (
              <span>{item}</span>
            ) : (
              <>
                <span>
                  {item.villeDepart.city + " - " + item.villeArrive.city}
                </span>{" "}
                |
                <span>
                  {new Date(item.departDate).toLocaleString("fr", {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </span>
                |<span>Places disponible: {item.nbrePlacesDispo}</span>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SuggestionField;
