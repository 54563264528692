import { apiSlice } from "../slices/apiSlice";

export const bookingApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommands: builder.query({
      query: () => "/bookings",
      providesTags: ["Command"],
    }),

    getNextCommands: builder.query({
      query: () => "/bookings/next",
      providesTags: ["NextCommand"],
    }),

    creatBooking: builder.mutation({
      query: (data) => ({
        url: "/bookings",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Command"],
    }),

    deleteBook: builder.mutation({
      query(id) {
        return {
          url: `/bookings/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Command"],
    }),

    updateBook: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `/bookings/${id}`,
          method: "PATCH",
          headers: {
            "content-type": "application/merge-patch+json",
          },
          body,
        };
      },
      invalidatesTags: ["Command"],
    }),
  }),
});

export const {
  useGetCommandsQuery,
  useGetNextCommandsQuery,
  useCreatBookingMutation,
  useDeleteBookMutation,
  useUpdateBookMutation,
} = bookingApi;
