import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {API_URL} from "../utilities/Constants"

const UserContext = createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw Error("useUserContext must be used inside an UserContextProvider");
  }

  return context;
};

export function UserContextProvider({ children }) {
  const [selectUser, setSelectUser] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (pathname.split("/").length <= 2 || !pathname.includes("users")) return;
  //   async function fetchData() {
  //     const httpRes = await fetch(
  //       API_URL+`/users/${pathname.split("/")[2]}`
  //     );

  //     const data = await httpRes.json();

  //     if (!httpRes.ok) {
  //       // navigate("/users");
  //     } else {
  //       setSelectUser(data);
  //     }
  //   }
  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  // console.log("UserContext : " , selectUser);

  return (
    <UserContext.Provider value={{ selectUser, setSelectUser }}>
      {children}
    </UserContext.Provider>
  );
}
