import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import SideMenu from "../components/SideMenu";
import Modals from "../components/Modals";
import { Suspense } from "react";
import { useModalContext } from "../context/ModalContext";

const ProtectedRoute = () => {
  const { userInfo } = useSelector((state) => state.auth);
  // const { isModalOpen } = useSelector((state) => state.modal);
  const { isModalShow,component } = useModalContext();


  return userInfo ? (
    <div className="relative h-screen overflow-hidden w-full">
      <NavBar />
      {/*  */}
      <div
        className={`relative w-full h-[calc(100vh-68px)] overflow-auto customScrollbar bg-bgGray flex flex-col lg:flex-row gap-5 lg:p-5  font-poppins  ${
          isModalShow && "blur-[2px]"
        }`}
      >
        <SideMenu />
        <div className="w-full relative h-full overflow-hidden  bg-white rounded-md">
          <Suspense
            fallback={
              <h1 className="w-full h-screen p-4 flex justify-center items-center">
                Loading...
              </h1>
            }
          >
            <Outlet />;
          </Suspense>
        </div>
      </div>
      {/*  */}
      {isModalShow && <Modals>{component}</Modals>}
    </div>
  ) : (
    <Navigate to={"/"} replace />
  );
};

export default ProtectedRoute;
