import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useModalContext } from "../../context/ModalContext";
import { isTimeValid } from "../../utilities";
import PhoneField from "../forms_comp/PhoneField";
import TextField from "../forms_comp/TextField";
// import SuggestionField from "../SuggestionField";

import {API_BASE_URL, API_URL} from "../../utilities/Constants"

const OfferForm = () => {
  const { user } = useAuthContext();
  const { setIsModalShow } = useModalContext();
  const [departD, setDepartD] = useState("");
  const [arriveD, setArriveD] = useState("");
  const [departTime, setDepartTime] = useState(
    new Date().toLocaleString("fr", { timeStyle: "medium" })
  );
  const [arriveTime, setArriveTime] = useState(
    new Date().toLocaleString("fr", { timeStyle: "medium" })
  );

  const [departedCity, setDepartedCity] = useState("");
  const [arrivedCity, setArrivedCity] = useState("");
  // const [status, setStatus] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [places, setPlaces] = useState(1);
  const [price, setPrice] = useState(1);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (name.includes("*")) {
      setPhone(name.split("*")[2]);
    }
  }, [name]);

  async function handleForm() {
    if (!name || !phone || !departD || !places || !departTime || !arriveTime) {
      setError("Tous les champs sont obligatiores");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    if (!isTimeValid(departTime) || !isTimeValid(arriveTime)) {
      setError("Le temps doit correspond le schéma suivant hh:mm:ss");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);

      return;
    }

    let httpRes = await fetch(API_URL+"/offers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({

        user: `/api/users/${name.split("*")[1]}`,
        departDate: new Date(departD + " " + departTime),
        arriverDate: new Date(arriveD + " " + arriveTime),

        status: "Draft",
        villeDepart: `/api/villes/${departedCity.split("*")[1]}`,
        villeArrive: `/api/villes/${arrivedCity.split("*")[1]}`,
        nbrePlaces: Number(places),
        price: Number(price),
      }),
    });

    let data = await httpRes.json();

    if (httpRes.ok) {
      navigate("/offers");
      setIsModalShow(false);
    } else {
      console.log(data);
      setError(data["hydra:description"]);
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }
  }

  return (
    <div className="absolute p-4 left-1/2 -translate-x-[calc(50%-50px)] top-10 mx-auto h-fit w-[45%] bg-white drop-shadow rounded-lg">
      <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
        Créer un nouveau offre
      </h2>

      <TextField
        type="text"
        label="Utilisateurs"
        value={name.split("*")[0]}
        setValue={setName}
      />

      <PhoneField type="tel" label="Phone" value={phone} setValue={setPhone} />

      <div className="grid grid-cols-2 gap-x-2">
        <TextField
          type="date"
          label="Date de départ"
          required
          customStyle="grid grid-cols-2"
          min={new Date().toISOString().split("T")[0]}
          value={departD}
          setValue={setDepartD}
        />
        <TextField
          type="text"
          label="Temps"
          required
          value={departTime}
          setValue={setDepartTime}
          customStyle="grid grid-cols-3 pl-4"
          secondStyle
        />
      </div>

      <div className="grid grid-cols-2 gap-x-2">
        <TextField
          type="date"
          label="Date d'arrivée"
          required
          customStyle="grid grid-cols-2"
          min={
            departD
              ? new Date(departD).toISOString().split("T")[0]
              : new Date().toISOString().split("T")[0]
          }
          value={arriveD}
          setValue={setArriveD}
        />
        <TextField
          type="text"
          label="Temps"
          required
          value={arriveTime}
          setValue={setArriveTime}
          customStyle="grid grid-cols-3 pl-4"
          secondStyle
        />
      </div>

      <TextField
        type="text"
        label="Ville de départ"
        value={departedCity.split("*")[0]}
        setValue={setDepartedCity}
      />
      <TextField
        type="text"
        label="Ville d'arrivée"
        value={arrivedCity.split("*")[0]}
        setValue={setArrivedCity}
      />

      <TextField
        type="number"
        label="N° de place dispo"
        min={1}
        value={places}
        setValue={setPlaces}
      />

      <TextField
        type="number"
        label="Price"
        min={1}
        value={price}
        setValue={setPrice}
      />

      {/* <SuggestionField
        suggestions={[
          "Status",
          "Brouillon",
          "En attente de publication",
          "Publié",
          "Annulé",
        ]}
        setValue={setStatus}
        isNormal={true}
      /> */}

      {error && <p className="text-center text-sm text-red-400">{error}</p>}
      <div className="flex space-x-4 mt-6 text-sm justify-end">
        <button
          onClick={handleForm}
          className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
        >
          crée
        </button>
        <button
          onClick={() => setIsModalShow(false)}
          className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
        >
          annuler
        </button>
      </div>
    </div>
  );
};

export default OfferForm;
