import { SearchIcon } from "../icons";

function SearchField({ placeholder }) {
  return (
    <div className="flex space-x-4 bg-bgGray px-4 py-1 rounded-full">
      <input type="text" placeholder={placeholder} className="bg-bgGray text-sm outline-none" />
      <SearchIcon width={22} color="#F1F1F1" className="stroke-fontColor stroke-[2px] placeholder:text-fontColor" />
    </div>
  );
}

export default SearchField;
