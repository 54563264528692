import { apiSlice } from "../slices/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/login",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),

//  NOT ROUTE FOR THIS URL   

//     logout: builder.mutation({
//       query: () => ({
//         url: "/logout",
//         method: "POST",
//       }),
//       invalidatesTags: ["User"],
//     }),
  }),
});

export const { useLoginMutation } = authApiSlice;
