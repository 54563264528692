import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../context/ModalContext";
import PhoneField from "../forms_comp/PhoneField";
import TextField from "../forms_comp/TextField";
import SuggestionField from "../SuggestionField";
import { useGetOffersQuery } from "../../redux/apiCalls/offersApi";
import { useCreatBookingMutation } from "../../redux/apiCalls/bookingApi";

function ComandForm() {
  const { setIsModalShow } = useModalContext();
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [passengers, setPassengers] = useState(1);
  const [bagage, setBagage] = useState(0);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const { data: offer, error: Err, isLoading } = useGetOffersQuery();
  const [creatBooking, { isLoading: load, error: creatError }] =
    useCreatBookingMutation();

  useEffect(() => {
    if (name.includes("*")) {
      setPhone(name.split("*")[2]);
    }
  }, [name]);

  async function handleForm() {
    if (!name || !phone || !passengers) {
      setError("Tous les champs sont obligatiores");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }

    if (passengers > selectedOffer.nbrePlacesDispo) {
      setError(`${selectedOffer.nbrePlacesDispo} places restantes disponibles`);
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }
    try {
      await creatBooking({
        userId: name,
        departedDate: "14:30 - 21/12/2022",
        createdDate: new Date().toLocaleString("fr-FR", {
          dateStyle: "short",
        }),
        reserved: passengers,
        totalPlaces: 5,
        isOffer: true,
        paymentMethod: "Orange Money",
        bagage,

        status: "active",
        user: `/api/users/${name.split("*")[1]}`,
        offer: selectedOffer["@id"],
        villeDepart: selectedOffer.villeDepart.city,
        villeArrive: selectedOffer.villeArrive.city,
        nbrePlace: Number(passengers),
      }).unwrap();
      navigate("/commands");
      setIsModalShow(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Créer une nouvelle commande
        </h2>

        <TextField
          type="text"
          label="Nom et prénom"
          value={name.split("*")[0]}
          setValue={setName}
        />

        <PhoneField
          type="tel"
          label="Phone"
          value={phone}
          setValue={setPhone}
        />

        <TextField
          type="text"
          label="Ville de départ"
          readonly={true}
          value={selectedOffer ? selectedOffer.villeDepart.city : ""}
          title="Read only"
        />
        <TextField
          type="text"
          label="Ville d'arrivée"
          readonly={true}
          value={selectedOffer ? selectedOffer.villeArrive.city : ""}
          title="Read only"
        />

        <div className="grid grid-cols-2 gap-x-4">
          <TextField
            type="number"
            label="Voyageurs"
            customStyle="grid grid-cols-2"
            min={1}
            value={passengers}
            setValue={setPassengers}
          />
          <TextField
            type="number"
            label="Bagage"
            customStyle="grid grid-cols-3 pl-12"
            secondStyle
            min={0}
            required
            value={bagage}
            setValue={setBagage}
          />
        </div>

        {offer && (
          <SuggestionField
            suggestions={offer["hydra:member"]}
            setValue={setSelectedOffer}
          />
        )}

        {error && <p className="text-center text-sm text-red-400">{error}</p>}
        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={handleForm}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            crée
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default ComandForm;
