import { memo, useEffect, useState } from "react";
import {API_URL} from "../../utilities/Constants"

function TextField({
  setValue,
  value,
  type,
  label,
  required,
  customStyle,
  secondStyle,
  min,
  max,
  maxLength,
  disabled,
  readonly,
  title,
  isOptions,
  optionsValues = [],
}) {
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  const [results, setResults] = useState(null);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function searchBy(url) {
      setLoading(true);
      const httpRes = await fetch(url);

      const data = await httpRes.json();

      if (!httpRes.ok) {
        console.log(data);
      } else {
        setResults(data["hydra:member"]);
      }
      setLoading(false);
    }

    if (isFocus) {
      if (
        label === "Nom et prénom" ||
        label === "Renseigner l'utisateur" ||
        label === "Utilisateurs"
      ) {
        searchBy(
          API_URL+`/users?profil.firstname=${value}&page=${1}&itemsPerPage=${6}`
        );
      }

      if (label.match(/ville/i)) {
        searchBy(
          API_URL+`/villes?city=${value}&page=${1}&itemsPerPage=${6}`
        );
      }
    }
  }, [label, value, isFocus]);

  function handleWindowClick(e) {
    if (e.target !== document.getElementById(label)) {
      setIsFocus(false);
      setResults(null);
    }
  }

  return (
    <div
      className={`relative w-full ${
        customStyle ? customStyle : "grid grid-cols-4"
      } items-center h-fit mb-4 text-sm text-logoBlack`}
    >
      <label htmlFor={label} className="w-11/12 break-words">
        {label}
      </label>
      {!isOptions && (
        <input
          title={title ? title : null}
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readonly}
          onFocus={() => setIsFocus(true)}
          min={min}
          max={max}
          id={label}
          className={`${
            customStyle
              ? `-ml-2 ${secondStyle && "col-span-2"}`
              : "-ml-3 col-span-3"
          } outline-none px-4 py-1 bg-bgGray rounded-md border border-bgGray focus:border-logoGreen`}
          type={type}
          required={required}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      )}

      {isFocus && results && !isOptions && (
        <ul className="z-10 absolute w-[calc(100%-128px)] left-32 top-full bg-bgGray border border-white rounded-b-md">
          {loading ? (
            <span className="text-center">loading...</span>
          ) : (
            results.map((result) => (
              <li
                onClick={(e) => {
                  let name =
                    label.includes("Nom") ||
                    label === "Renseigner l'utisateur" ||
                    label === "Utilisateurs"
                      ? result.profil.firstname +
                        " " +
                        result.profil.lastname +
                        "*" +
                        result.id +
                        "*" +
                        result.phone
                      : result.city + "*" + result.id;
                  setValue(name);
                  setIsFocus(false);
                }}
                key={result.id}
                className="capitalize px-8 py-3 duration-100 ease-in hover:bg-logoGreen hover:text-white cursor-pointer"
              >
                {label.includes("Nom") ||
                label === "Renseigner l'utisateur" ||
                label === "Utilisateurs"
                  ? result.profil.firstname + " " + result.profil.lastname
                  : result.city}
              </li>
            ))
          )}
        </ul>
      )}

      {isOptions && (
        <select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id="status"
          className="col-span-3 py-1 px-3 -ml-3 bg-bgGray rounded-md border border-bgGray focus:border-logoGreen outline-none"
        >
          {optionsValues.map((el, key) => (
            <option value={el} key={key} className="capitalize">
              {el}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

export default memo(TextField);
