import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../context/ModalContext";
import TextField from "../forms_comp/TextField";
import { useDeleteOptionMutation } from "../../redux/apiCalls/optionsApi";
import { toast } from "react-toastify";

function OptionDelete({ optionInfo }) {
  const { id, name } = optionInfo;
  const { setIsModalShow } = useModalContext();
  const navigate = useNavigate();
  const [deleteHandler, { error }] = useDeleteOptionMutation();

  async function handleForm(_e) {
    try {
      await deleteHandler(id).unwrap();
      navigate("/options");
      setIsModalShow(false);
      toast.success("Option Deleted successfully");
    } catch (error) {
      console.log(error);
      toast.error(error.status);
    }
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Désactiver l'Option
        </h2>

        <TextField
          type="text"
          label="Votre Option"
          required
          value={name}
          disabled
        />
        {error && <p className="text-center text-sm text-red-400">{error}</p>}
        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={handleForm}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            Suprimer
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}
export default OptionDelete;
