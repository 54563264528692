import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const API_URL = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth?.userInfo?.token;
    //     console.log(token, "TOKEN");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    "User",
    "Chat",
    "Command",
    "Offers",
    "NextCommand",
    "Options",
    "Newsletters",
    "Car",
    "Cities",
    "Coupon",
    "commission",
    "Users",
    "Admins",
    "Payment",
    "Mode_payement",
    "Review"
  ],
  endpoints: (builder) => ({}),
});
