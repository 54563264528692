function CheckedBox({ containerStyle, label, isChecked, listener }) {
  return (
    <div className={`${containerStyle}`}>
      <input
        type="checkbox"
        onClick={listener}
        id={label}
        className={`appearance-none cursor-pointer w-3 h-3 rounded-full outline outline-1 outline-logoGreen outline-offset-2 ${
          isChecked && "bg-logoGreen"
        } `}
      />
      <label htmlFor={label} className="text-logoBlack cursor-pointer ml-2 text-[13px]">
        {label}
      </label>
    </div>
  );
}

export default CheckedBox;
