import { useState } from "react"

import {API_BASE_URL} from "../utilities/Constants"
export function useVerifyToken() {
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)

  const verifyToken = async (token) => {
    setIsLoading(true);
    setError(null)

    // console.log(JSON.stringify({ token }));
    const response = await fetch(API_BASE_URL+`/forgot_password/${token}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    });

    const json = await response.json();
    if (!response.ok) {
      setIsLoading(false)
      setError(json.message)
    } else {
      // console.log(json)
      setIsLoading(false)
    }

  };
  return { verifyToken, isLoading, error };
}

