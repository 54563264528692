import jwt_decode from "jwt-decode";

let charaters = "azertyuiopqsdfghjklmwxcvbn0123456789";
let regExForTime = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/g; // match HH:MM:SS

export function generateACoupons(length) {
  let coupons = [];
  for (let i = 0; i < length; i++) {
    let num = Math.floor(Math.random() * (charaters.length - 1));
    coupons.push(charaters[num].toUpperCase());
  }

  return coupons.join("");
}

let validRegEx =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

let validName = /^[a-zA-Z-' ]*$/;

function checkNameIsValidate(name) {
  if (!name)
    return { isValid: false, errorMsg: "Le nom ou le prénom est obligatoire" };

  if (!name.match(validName))
    return {
      isValid: false,
      errorMsg: "Seules les lettres et les espaces blancs sont autorisés",
    };

  return { isValid: true };
}

function checkEmailIsValidate(email) {
  if (!email) return { isValid: false, errorMsg: "L'e-mail est obligatoire" };

  if (!email.match(validRegEx))
    return {
      isValid: false,
      errorMsg: "L'e-mail doit correspondre à example@mail.com",
    };

  return { isValid: true };
}

function decodeJWT(token) {
  return jwt_decode(token);
}

function isTimeValid(time) {
  if (!time.match(regExForTime)) return false;
  return true;
}

export { checkNameIsValidate, checkEmailIsValidate, decodeJWT, isTimeValid };
