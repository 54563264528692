import CustomRow from "../CustomRow";

const LastComandsRow = ({ infos }) => {
  return (
    <div className="grid grid-cols-3 place-items-start px-4 py-3">
      <CustomRow
        description={
          infos.user.profil?.firstname + " " + infos.user.profil?.lastname
        }
        containerStyle="space-x-2 "
        underTitle={infos.offer.villeDepart.city}
        textStyle="capitalize text-[13px] text-logoBlack leading-5 font-medium"
        underStyle="capitalize text-fontColor text-[12px] font-normal"
      >
        <img
          src="/assets/avatar.png"
          alt="avatar"
          className="w-9 h-9 object-cover object-center rounded-full"
        />
      </CustomRow>

      <p className="text-[13px] justify-self-center text-fontColor">
        {"+212 54667876".toLocaleString("fr")}
        <br />
        <span className="capitalize">
          {infos.offer.villeArrive.city}
        </span>
      </p>

      <p className="justify-self-end text-[13px] text-fontColor text-end">
        {new Date(infos.createdAt).toLocaleString("fr", {
          timeStyle: "medium",
        })}
        <br />
        <span>
          {new Date(infos.createdAt).toLocaleString("fr", {
            dateStyle: "short",
          })}
        </span>
      </p>
    </div>
  );
};

export default LastComandsRow;
