import { apiSlice } from "../slices/apiSlice";

export const commissionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommission: builder.query({
      query: () => "/commissions",
      providesTags: ["commission"],
    }),
  }),
});

export const { useGetCommissionQuery } = commissionsApi;
