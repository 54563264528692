import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../context/ModalContext";
import TextField from "../forms_comp/TextField";
import { useUpdatePaymentModeMutation } from "../../redux/apiCalls/paymentsApi";
import { toast } from "react-toastify";

function PayementMethodEdit({ optionInfo }) {
  const { id, name, description } = optionInfo;
  const { setIsModalShow } = useModalContext();
  const [methodePayement, setMethodePayement] = useState(name || "");
  const [desc, setDesc] = useState(description || "");
  const [error, setError] = useState(false);
  const [updateHandler] = useUpdatePaymentModeMutation();

  const navigate = useNavigate();

  async function handleForm(_e) {
    if (!methodePayement) {
      setError("Tous les champs sont obligatiores");
      let timer = setTimeout(() => {
        setError(false);
        clearTimeout(timer);
      }, 5000);
      return;
    }
    try {
      await updateHandler({
        id,
        body: JSON.stringify({
          name: methodePayement,
          description: desc,
        }),
      }).unwrap();
      navigate("/modepayements");
      setIsModalShow(false);
      toast.success("Payments methode updated");
    } catch (error) {
      console.log(error);
      toast.error(error.status);
    }
  }

  return (
    <div className="absolute  flex flex-col justify-center items-center h-full w-full  drop-shadow ">
      <div className="h-fit lg:w-[50%] w-[90%] bg-white rounded-lg p-4">
        <h2 className="mb-8 text-logoBlack text-sm font-medium text-center">
          Modifié la methode de payement
        </h2>

        <TextField
          type="text"
          label="method de payement"
          required
          value={methodePayement}
          setValue={setMethodePayement}
        />
        <TextField
          type="text"
          label="description"
          required
          value={desc}
          setValue={setDesc}
        />
        {error && <p className="text-center text-sm text-red-400">{error}</p>}
        <div className="flex space-x-4 mt-6 text-sm justify-end">
          <button
            onClick={handleForm}
            className="bg-logoGreen duration-100 hover:bg-logoGreen/80 text-white py-2 w-[245px] capitalize rounded-md"
          >
            Enregistre
          </button>
          <button
            onClick={() => setIsModalShow(false)}
            className="bg-blue-300/50 duration-100 hover:bg-blue-300/80 text-logoBlack py-2 w-[185px] capitalize rounded-md"
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
}
export default PayementMethodEdit;
